<template>
  <div class="p-10 bg-white shadow rounded-md">
    <div class="flex flex-col items-center">
      <label for="url" class="block text-lg font-medium text-gray-700">
        Instagram linkini gir
      </label>
      <input type="text" name="url" v-model="instagramPhoto.text" id="url" autocomplete="given-name" class="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-96 shadow-sm sm:text-sm border-gray-300 rounded-md">
      <button @click="addInstagramPhoto" type="submit" class="inline-flex justify-center w-32 py-2 px-4 border border-transparent mt-3 shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Ekle
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomAddPopup",
  props: ['instagramPhoto'],
  methods: {
    addInstagramPhoto() {
      /* eslint-disable */
      const regex = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/p\/([A-Za-z0-9-_\.]+)/g;
      const match = regex.exec(this.instagramPhoto.text);

      if (match !== null) {
        this.fetchInstagramPhoto(this.instagramPhoto.text).then(res => {
          this.$emit('addPhoto', url);
          const photo = res.graphql.shortcode_media.display_resources[0].src;
          if (photo !== undefined) {
            this.$store.dispatch('addPhotoToRoom', {url: match[1], photo, code: this.$route.params.code});
            this.$toasted.show("Sıraya Eklendi");
          }
        });
      }
    },
    fetchInstagramPhoto(url) {
      return fetch(`${url}?__a=1`)
        .then(res => res.json());
    },
  }
}
</script>
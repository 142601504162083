<template>
  <div class="room h-screen">
    <Nav></Nav>
    <template v-if="notFound">
      <div class="w-full h-screen flex justify-center items-center text-2xl text-gray-700" v-show="notFound">404 link sikilmiş.</div>
    </template>
    <template v-else>
      <Popup index="0" :active="popups.add">
        <RoomAddPopup @addPhoto="addPhoto" :instagramPhoto.sync="instagramPhoto"></RoomAddPopup>
      </Popup>
      <div v-if="isLogged" class="pt-30 bg-gray-50 h-screen flex items-center">
        <div class="pt-3 max-w-5xl mx-auto px-2 sm:px-6 lg:px-8 w-full flex gap-4 relative">
          <template v-if="isAdmin">
            <div class="absolute -left-6 cursor-pointer" style="top: 15rem;" v-if="currentPhoto" @click="currentPhoto = currentPhoto - 1">
              <svg class="h-12 w-12 text-gray-600 group-hover:text-gray-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M15.422 7.406l-4.594 4.594 4.594 4.594-1.406 1.406-6-6 6-6z" clip-rule="evenodd"></path>
              </svg>
            </div>
            <div class="absolute -right-6 cursor-pointer" style="top: 15rem;" v-if="photos.length && currentPhoto !== photos.length - 1" @click="currentPhoto = currentPhoto + 1">
              <svg class="h-12 w-12 text-gray-600 group-hover:text-gray-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8.578 16.594l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z" clip-rule="evenodd"></path>
              </svg>
            </div>
          </template>
          <div class="grid grid-cols-12 gap-3 w-full">
            <div class="col-span-8 bg-white shadow" style="height: 32rem;">
              <template v-if="photosLoaded">
                <template v-if="photos.length">
                  <img class="w-full h-full object-contain rounded-md" :src="photos[currentPhoto].photo">
                </template>
                <template v-else>
                  <div class="w-full h-full flex justify-center items-center">
                    31
                  </div>
                </template>
              </template>
            </div>
            <div class="col-span-1">
              <div class="shadow py-3 bg-white h-full rounded-md cursor-pointer flex flex-col justify-center items-center gap-3">
                <div class="flex-1 h-full">
                  <vue-range-slider :disabled="photos.length === 0" @slide-end="changeColor" :width="6" :height="380" v-model="slider.score" direction="vertical" :min="0" :max="10" :step="0.01"
                    :tooltip-style="slider.style.default" :bg-style="slider.style.light" :process-style="slider.style.colorful">
                  </vue-range-slider>
                </div>
                <button @click="votePhoto" type="button" :disabled="!photos.length" :class="{ 'disabled:opacity-50': !photos.length }" class="inline-flex justify-center p-2 items-center border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button @click="popups.add = true" type="button" class="inline-flex justify-center p-2 items-center border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.016 18.984h12l-4.031-4.969-3 3.984-1.969-3zM15.984 11.016h3v7.969q0 0.797-0.586 1.406t-1.383 0.609h-12q-0.797 0-1.406-0.609t-0.609-1.406v-12q0-0.797 0.609-1.383t1.406-0.586h7.969v3h3v3zM18.984 6.984v3h-1.969v-3h-3v-1.969h3v-3h1.969v3h3v1.969h-3z" clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="w-full col-span-3 shadow bg-white p-3 rounded-md relative">
              <div class="flex flex-col gap-2">
                <li v-for="(user, index) in this.room.users" :key="index" class="p-3 flex flex-col rounded-lg hover:bg-gray-50 cursor-pointer">
                  <div class="flex">
                    <span class="flex-shrink-0 h-6 w-6 text-indigo-600">
                      {{getIconById(user.icon).icon}}
                    </span>
                    <p class="ml-4 text-base font-medium text-gray-900">
                      {{user.name}}
                    </p>
                    <div class="text-right ml-auto">
                      <span class="text-xs font-semibold inline-block text-yellow-600" v-if="photos[currentPhoto] && photos[currentPhoto].points.find(point => point.user === user.name)">
                        {{ photos[currentPhoto].points.find(point => point.user === user.name).point }}
                      </span>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="w-full h-screen flex justify-center items-center bg-gray-50">
        <div class="p-10 bg-white shadow rounded-md">
          <div class="flex flex-col items-center" v-if="!isLogged">
            <label for="name" class="block text-lg font-medium text-gray-700">
              Sana nasıl hitap edelim?
            </label>
            <input type="text" v-model="fakeUser.name" name="name" id="name" autocomplete="given-name" class="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-64 shadow-sm sm:text-sm border-gray-300 rounded-md">
            <label for="name" class="block text-lg font-medium text-gray-700 mt-3">
              Bir emoji seç
            </label>
            <div class="mt-3 grid grid-cols-6 max-h-40 overflow-y-auto">
              <span :class="{ 'bg-gray-100': fakeUser.icon === index, 'opacity-50': (icon.used), 'cursor-not-allowed': (icon.used) }"
                    class="cursor-pointer p-1 m-0.5 hover:bg-gray-100 rounded-md" @click="$set(fakeUser, 'icon', index)" v-for="(icon, index) in getIcons" :key="index">
                {{icon.icon}}
              </span>
            </div>
            <button @click="createUser" type="submit" class="inline-flex justify-center w-32 py-2 px-4 border border-transparent mt-3 shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Odaya Gir
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import { generateString } from '@/helpers/global.js';
//import io from "socket.io-client";
import Nav from "../components/Nav";
import Popup from "../components/Popup";
import RoomAddPopup from "../components/RoomAddPopup";
import VueRangeSlider from 'vue-range-component';
import 'vue-range-component/dist/vue-range-slider.css'
export default {
  name: 'Room',
  components: {Nav, Popup, RoomAddPopup, VueRangeSlider},
  data: () => ({
    popups: {
      add: false
    },
    room: {},
    user: {},
    fakeUser: {},
    photos: [],
    photosLoaded: false,
    currentPhoto: -1,
    notFound: false,
    instagramPhoto: {text: ''},
    slider: {
      score: 0,
      style: {
        default: { backgroundColor: '#fa7894', borderColor: '#fa7894' },
        light: { backgroundColor: '#eceff1' },
        colorful: { backgroundImage: `linear-gradient(to top, #fee140 100%, #fa709a 100%)` }
      }
    }
  }),
  computed: {
    ...mapGetters([
      'getIcons',
      'getIconById'
    ]),
    isLogged() {
      if (this.room.users === undefined || this.user === undefined) return false;
      
      if (this.room.users.find(user => user.name === this.user.name)) return true;
      return false;
    },
    isAdmin() {
      if (this.room.users === undefined || this.user === undefined) return false;

      if (this.room.users.find(user => user.name === this.user.name && user.admin)) return true;
      return false;
    }
  },
  created() {
    this.loadRoom();
  },
  watch: {
    currentPhoto(val, oldVal) {
      if (oldVal !== -1)
        this.changePhoto();
    }
  },
  methods: {
    loadRoom() {
      this.$store.dispatch('fetchRoom', this.$route.params.code).then(snapshot => {
        snapshot.onSnapshot((doc) => {
          if (doc.exists) {
            this.room = doc.data();
            this.currentPhoto = this.room.currentPhoto;

            this.room.users.map(user => {
              this.getIcons[user.icon].used = true;
            });
            
            this.loadUser();

          }else this.notFound = true;
        });
      });

      /*
      //this.socket.emit("room", {room: this.$route.params.code, user: this.user});
      */
    },
    loadUser() {
      let user = localStorage.getItem('user');
      if (user !== null) {
        this.user = JSON.parse(user);
        this.loadPhotos();
      }else {
        this.fakeUser.icon = this.getIcons.findIndex(icon => icon.used === undefined);
      }
    },
    createUser() {
      this.fakeUser.code = this.fakeUser.name + generateString(50);
      localStorage.setItem('user', JSON.stringify(this.fakeUser));
      this.$store.dispatch('createUser', {code: this.fakeUser.code, name: this.fakeUser.name}).then(() => {
        this.fakeUser.admin = false;
        if (this.room.users.length === 0) this.fakeUser.admin = true;
        this.$store.dispatch('addUserToRoom', {room: this.$route.params.code, user: this.fakeUser}).then(() => {
          this.loadPhotos();
        });
      });
    },
    loadPhotos() {
      this.$store.dispatch('fetchPhotos', this.$route.params.code).then(docSnapshot => {
        docSnapshot.onSnapshot(querySnapshot => {
          this.photosLoaded = true;
          let photoArray = [];
          querySnapshot.forEach(doc => {
            photoArray.push({id: doc.id, ...doc.data()});
          });
          this.photos = photoArray;
          this.setSliderScore();
        });
      });
    },
    addPhoto() {
      this.instagramPhoto.text = '';
      this.popups.add = false;
    },
    changePhoto() {
      this.$store.dispatch('changePhoto', {room: this.$route.params.code, index: this.currentPhoto});
      this.setSliderScore();
    },
    changeColor() {
      const newGradient = 100 - (this.slider.score) * 10;
      this.slider.style.colorful.backgroundImage = `linear-gradient(to top, #fee140 ${newGradient}%, #fa709a 100%)`;
    },
    votePhoto() {
      this.$store.dispatch('votePhoto', { room: this.$route.params.code, user: this.user.name, photo: this.photos[this.currentPhoto].id, point: this.slider.score });
    },
    setSliderScore() {
      if (!this.photos.length) return;
      
      const point = this.photos[this.currentPhoto].points.find(point => point.user === this.user.name);
      if (point !== undefined) this.slider.score = point.point;
      else this.slider.score = 0;
    },
  }
}
</script>

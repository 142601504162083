<template>
  <div class="fixed top-0 left-0 z-30 flex justify-center items-center w-screen h-screen transition duration-300 ease cursor-pointer" :class="{ 'opacity-1 visible': active, 'opacity-0 invisible': !active }">
    <div class="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-50" @click="$parent.popups[Object.keys($parent.popups)[index]] = false"></div>
    <div class="z-40 max-w-md w-full">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'popup',
    props: ['active', 'index'],
  }
</script>